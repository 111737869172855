<template>
  <div class="mt-4">
    <h2>{{label}}</h2>
    <div class="row mb-5">
      <div
          class="col-xxl-4 col-md-4 col-sm-6 mb-3"
          v-for="(item, index) in filteredPlans"
          :key="index">
        <div class="new-plan-card call-text-container">
          <call-out-text :callOutText="item.callout_text" />
          <div class="">
            <div class="img-wrap">
              <img :src="item.plan_logo" />
            </div>
            <h5>{{ item.plan_name }}</h5>
            <ul>
              <p v-html="item.plan_short_text"></p>
            </ul>
          </div>
          <div class="plan-price-container">
            <price-detail-info :priceInfo="item?.tier_price" v-if="tempId"/>
            <div>
              <button class="view-btn"  v-on:click="nextPage(item.plan_id)">
                View More Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PriceDetailInfo from "./PriceDetailInfo.vue";
import CallOutText from "./CallOutText";

export default {
  name: 'BundlePlan',
  components: {PriceDetailInfo, CallOutText},
  props: {
    label: {
      type: String,
      require: false
    },
    tempId: {
      type: String,
      require: false
    },
    filteredPlans: {
      type: Array,
      require: true
    }
  },
  methods: {
    nextPage (planId) {
      let app = this
       app.$emit('nextPage', planId)
    }
  }
}
</script>
<style scoped>
h2 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-bottom: 1.5rem;
}
.view-btn {
  border: 1px solid #0099cc;
  color: #0099cc;
  padding: 6px 0rem;
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  margin-top: 0.5rem;
}
</style>
