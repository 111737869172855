<template>
  <div class="label-plan-tag" v-if="callOutText">{{callOutText}}</div>
</template>

<script>
export default {
  name: "CallOutText",
  props: {
    callOutText: {
      type: String
    }
  }
}
</script>

<style scoped>
</style>